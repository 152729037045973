
import dayjs, {Dayjs} from "dayjs";
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    UnwrapRef,
} from "vue";
import UploadImage from "@/components/view/UploadImage.vue";
import {searchClinic} from "@/api/clinic";
import AddHospitalVue from "../hospital/addHospital.vue";
import {createDentist, getDentistInfo, updateDentist, getPosition} from "@/api/doctor";
import {message} from "ant-design-vue";
import {Rule} from "ant-design-vue/lib/form";
import STATUS from "@/components/model/enum";
import {getDate} from "@/components/ts/components";
import {isMobile, isEmail} from "@/utils/validate";
import UploadImagePreview from "@/components/view/UploadImagePreview.vue";
import {getTreatTypes} from "@/api/sale_order";
import {pageListCaseOrder} from "@/api/patientCaseOrder";

interface Date {
    hospitalOption: any[];
    hospitalSelectOption: any[];
    hospitalId: string;
    addHospitalloading: boolean;
    formState: {
        clinicIds: string[];
        license: string;
        level: string;
        password: string;
        id: string;
        teamId: string;
        positionId: string;
        areasOfExpertise: string;
        practiceCharacteristics: string;
        treatIds: any[];
        exhibitCaseIds: any[];
        reviewStatus: any;
    };
    addHospitalvisible: boolean;
    imageList: any[];
    avatarUrl: any;
    disabled: boolean;
    doctorId: number;
    clinicIdNames: any[];
    treeData: any[];
    options: any[];
    exhibitCaseOptions: any[];

}

interface Person {
    name: string;
    email: string;
    mobile: string;
    gender: string;
    born: string;
    idNumber: string;
    id: string;
    owner: string
}

let timeout: any;
let currentValue = "";

function fetch(value: string, callback: any) {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;

    function fake() {
        const options = {
            page: {
                pageSize: 100,
                currPage: 1,
            },
            keyword: value,
        };
        searchClinic(options).then((res: any) => {
            if (currentValue === value) {
                const data: any[] = [];
                if (res) {
                    console.log(res.data);
                    const result = res.data;
                    result.forEach((r: any) => {
                        data.push({
                            value: r.id,
                            label: r.shortName,
                        });
                    });
                }
                callback(data);
            }
        });
    }

    timeout = setTimeout(fake, 300);
}

export default defineComponent({
    components: {
        UploadImage,
        AddHospitalVue,
        UploadImagePreview
    },
    props: ["id", "type", "teamId", "treeData"],
    emits: ["onCancel"],
    mounted() {
        this.selectPositions()
    },
    setup(props, context) {

        const person: UnwrapRef<Person> = reactive({
            name: "",
            email: "",
            mobile: "",
            gender: "Male",
            born: "",
            idNumber: "",
            id: "",
            owner: ""
        });
        const data: UnwrapRef<Date> = reactive({
            hospitalOption: [],
            hospitalSelectOption: [],
            hospitalId: "",
            addHospitalloading: false,
            formState: {
                clinicIds: [],
                license: "",
                level: "",
                password: "",
                teamId: props.teamId,
                id: "",
                positionId: "",
                areasOfExpertise: '',
                practiceCharacteristics: '',
                treatIds: [],
                exhibitCaseIds: [],
                reviewStatus: 2,
            },
            addHospitalvisible: false,
            imageList: [
                {
                    title: "执业证书正面",
                    path: "",
                    type: "DENTIST_LICENSE_FRONT",
                    id: ''
                },
                {
                    title: "执业证书背面",
                    path: "",
                    type: "DENTIST_LICENSE_BACK",
                    id: ''
                },
                {
                    title: "补充资料",
                    path: "",
                    type: "DENTIST_DOCUMENT",
                    id: ''
                },
            ],
            avatarUrl: {
                title: "头像",
                path: "",
                type: "AVATAR",
                id: ''
            },
            disabled: false,
            doctorId: 0,
            clinicIdNames: [],
            treeData: [],
            options: [],
            exhibitCaseOptions: [],
        });
        let validate = async (value: string, message: string) => {
            console.log(data.formState[value as keyof typeof data.formState]);
            if (data.formState[value as keyof typeof data.formState] === "") {
                return Promise.reject(message);
            } else {
                return Promise.resolve();
            }
        };
        let doctorImagevalidator = () => {
            let flag = true;
            data.imageList.forEach((item) => {
                if (!item.path && (item.type === 'DENTIST_LICENSE_FRONT' || item.type === 'DENTIST_LICENSE_BACK')) {
                    flag = false;
                }
            });
            if (flag) {
                return Promise.resolve();
            } else {
                return Promise.reject("请上传执业证书!");
            }
        };
        const rules: Record<string, Rule[]> = {
            name: [
                {
                    required: true,
                    trigger: "change",
                    validator: (rule, value) => {
                        if (!value) {
                            return Promise.reject("请输入姓名!");
                        }
                        if (value.length > 32 || value.length < 2) {
                            return Promise.reject("姓名长度应为2-32个字!");
                        } else {
                            return Promise.resolve();
                        }
                    },
                },
            ],
            // mobile: [{ required: true,message: '请填写号码!', trigger: 'change' }],
            mobile: [
                {
                    required: true,
                    trigger: "change",
                    validator: (rule, value) => {
                        if (value == "") {
                            return Promise.reject("请填写手机号!");
                        }
                        if (!isMobile(value)) {
                            return Promise.reject("手机号格式错误!");
                        } else {
                            return Promise.resolve();
                        }
                    },
                },
            ],
            email: [
                {
                    type: "email",
                    required: false,
                    trigger: "change",
                    validator: (rule, value) => {
                        if (value == "") {
                            return Promise.resolve();
                        }
                        if (!isEmail(value)) {
                            return Promise.reject("邮箱格式错误!");
                        } else {
                            return Promise.resolve();
                        }
                    },
                },
            ],
            clinicIds: [
                {
                    required: true,
                    // validator: () => validate("clinicIds", "请选择所属医院!"),
                    validator: () => {
                        if (data.formState.clinicIds == null || data.formState.clinicIds.length == 0) {
                            return Promise.reject("请选择所属医院!");
                        } else {
                            return Promise.resolve();
                        }
                    },
                    trigger: "change",
                },
            ],
            license: [
                {
                    required: true,
                    validator: () => validate("license", "请输入资格证书编号!"),
                    trigger: "change",
                },
            ],
            level: [
                {
                    required: true,
                    validator: () => validate("level", "请选择医生职位!"),
                    trigger: "change",
                },
            ],
            doctorImage: [
                {required: true, validator: doctorImagevalidator, trigger: "change"},
            ],
            password: [
                {
                    required: true,
                    validator: () => validate("password", "请输入密码!"),
                    trigger: "change",
                },
            ],
        };
        const createDentistRef = ref();
        // 新建医生
        const onSubmit = () => {
            console.log('新建');
            createDentistRef.value.validate().then(() => {
                const option = {
                    ...person,
                    ...data.formState,
                    teamId: props.teamId,
                    docs: [...data.imageList],
                };
                console.log(option);
                option.docs.push(data.avatarUrl);
                if (props.type == 'update') {
                    update(option);
                } else {
                    add(option);
                }
                console.log("submit!", option);
            }).catch(() => {
                // context.emit("onCancel", false);
            })
        };
        const add = (option: any) => {
            createDentist(option).then(() => {
                message.success("创建成功");
                createDentistRef.value?.resetFields();
                context.emit("onCancel", true);
            }).catch(() => {
                // context.emit("onCancel", false);
            })
        }

        const update = (option: any) => {
            console.log("修改医");
            updateDentist(option).then(() => {
                console.log('修改医生成功');
                createDentistRef.value?.resetFields();
                context.emit("onCancel", true);
            }).catch(() => {
                // context.emit("onCancel", false);
            })
        }
        const selectPositions = () => {
            console.log("查询职位");
            if(props.teamId){
                getPosition(props.teamId).then((res: any) => {
                    data.treeData = res
                }).catch(() => {
                    // context.emit("onCancel", false);
                })
            }
        }
        // 不可选日期设置
        const disabledDate = (current: Dayjs) => {
            // Can not select days before today and today
            return current && current > dayjs().endOf("day");
        };
        // 上传成功回调
        const onSuccess = (url: string, type: number) => {
            console.log(url, type);
            if (type == 99) {
                data.avatarUrl.path = url;
            } else {
                data.imageList[type].path = url;
                createDentistRef.value.validate("doctorImage")
            }
        };
        const onRemove = (type: number) => {
            console.log(type);
            data.imageList[type].path = '';
            createDentistRef.value.validate("doctorImage")
        }
        // 模糊搜索医院
        const handleSearch = (val: string) => {
            fetch(val, (d: any[]) => (data.hospitalOption = d));
        };
        // 切换医院
        const handleChange = (val: any[]) => {
            console.log('切换', val);
            data.formState.clinicIds = val;
            data.hospitalSelectOption = data.hospitalOption.filter((item: any) => val.includes(item.value));
            // fetch(val, (d: any[]) => (data.hospitalOption = d));
        };
        // 新建医院
        const addHospital = () => {
            console.log("新建医院");
            data.addHospitalvisible = true;
        };
        const onCancel = () => {
            console.log("取消");
            data.addHospitalloading = false
            data.addHospitalvisible = false;
        };
        const handleCancel = () => {
            console.log("取消");
            createDentistRef.value?.resetFields();
            context.emit("onCancel");
        };
        const init = async () => {
            console.log(props);
            if (props.id) {
                await onSearchExhibitCase(props.id);
                fetch('', (d: any[]) => (data.hospitalOption = d));
                data.disabled = true;
                data.doctorId = props.id;
                const res = await getDentistInfo(props.id);
                console.log(res);
                for (const key in person) {
                    person[key as keyof typeof person] = res[key];
                }
                for (const key in data.formState) {
                    data.formState[key as keyof typeof data.formState] = res[key];
                    data.formState.treatIds = res.dentistTreats?.map((e: any) => {
                        return e.treatId
                    })
                    data.formState.exhibitCaseIds = res.exhibitCases?.map((e: any) => {
                        return e.id
                    })
                }
                data.formState.clinicIds = []
                data.hospitalSelectOption = []
                res.clinicBos?.forEach((element: any) => {
                    data.formState.clinicIds.push(element.id)
                    data.hospitalSelectOption.push({value: element.id, label: element.shortName})
                });
                person.born = res.born ? getDate(res.born) : "";
                data.imageList = []
                data.imageList = res.docs.filter((e: any) => {
                    return e.type === 'DENTIST_LICENSE_FRONT' || e.type === 'DENTIST_LICENSE_BACK' || e.type === 'DENTIST_DOCUMENT'
                });
                let avatar: any[] = res.docs.filter((e: any) => {
                    return e.type === 'AVATAR'
                });
                data.avatarUrl = avatar.length > 0 ? avatar[0] : {
                    title: "头像",
                    path: "",
                    type: "AVATAR",
                    id: ''
                }
                console.log(data.imageList)
                console.log(data.avatarUrl)
            }
        };
        const onSearchTreats = () => {
            getTreatTypes('0').then((res: any[]) => {
                data.options = res;
            })
        }
        const onSearchExhibitCase = (id: string) => {
            pageListCaseOrder({dentistId: id, page: {currPage: 1, pageSize: 100}}).then((res: any) => {
                let list: any[] = res && res.data ? res.data : [];
                data.exhibitCaseOptions = list.map((e: any) => {
                    return {
                        label: e.patientName,
                        value: e.id
                    }
                })
                // data.exhibitCaseOptions = res&&res.data?res.data:[];
            })
        }
        const handleClose = (tag: any) => {
            data.hospitalSelectOption = data.hospitalSelectOption.filter((item: any) => item.value !== tag);
            data.formState.clinicIds = data.formState.clinicIds.filter((item: any) => item !== tag);
        }

        const toClinic = (tag: any) => {
            data.hospitalId = tag;
            data.addHospitalvisible = true;
        }
        const addHospitalRef = ref()
        const onOk = () => {
            data.addHospitalloading = true
            addHospitalRef.value.onSubmit()
        }
        onMounted(async () => {
            await init();
            onSearchTreats();
        });
        return {
            labelCol: {style: {width: "100px"}},
            wrapperCol: {span: 14},
            person,
            createDentistRef,
            rules,
            onSubmit,
            disabledDate,
            onSuccess,
            onRemove,
            handleSearch,
            handleChange,
            addHospital,
            data,
            handleCancel,
            onCancel,
            STATUS,
            selectPositions,
            handleClose,
            toClinic,
            onOk,
            addHospitalRef,
        };
    },
});
