import request from '@/utils/request'

/**-----------------------------病例订单------------------------------------------ */

export const pageListCaseOrder: any = (data: any) => {
    return request({
        url: '/patient/case/order/list',
        method: 'post',
        data
    })
}


export const caseOrderDetail: any = (id: string) => {
    return request({
        url: `/patient/case/order/details/${id}`,
        method: 'get',
    })
}