import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-217d52af"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "warp-left" }
const _hoisted_2 = { class: "warp-conen" }
const _hoisted_3 = { class: "warp-right" }
const _hoisted_4 = { class: "carousel" }
const _hoisted_5 = { class: "doctorImage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadImage = _resolveComponent("UploadImage")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_UploadImagePreview = _resolveComponent("UploadImagePreview")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_AddHospitalVue = _resolveComponent("AddHospitalVue")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.person,
          "label-col": _ctx.labelCol,
          "wrapper-col": _ctx.wrapperCol,
          autoComplete: "off",
          rules: _ctx.rules,
          ref: "createDentistRef"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_UploadImage, {
                  onOnSuccess: _cache[0] || (_cache[0] = (url) => _ctx.onSuccess(url, 99)),
                  imageUrl: _ctx.data.avatarUrl.path
                }, null, 8, ["imageUrl"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_a_form_item, {
                  label: "姓名",
                  name: "name"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _ctx.person.name,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.person.name) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "生日" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_date_picker, {
                      value: _ctx.person.born,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.person.born) = $event)),
                      "disabled-date": _ctx.disabledDate,
                      valueFormat: "YYYY-MM-DD"
                    }, null, 8, ["value", "disabled-date"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "性别" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio_group, {
                      value: _ctx.person.gender,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.person.gender) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio, { value: "Male" }, {
                          default: _withCtx(() => [
                            _createTextVNode("男")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_radio, { value: "Female" }, {
                          default: _withCtx(() => [
                            _createTextVNode("女")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: "手机号",
                        name: "mobile"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            disabled: !!_ctx.id,
                            value: _ctx.person.mobile,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.person.mobile) = $event))
                          }, null, 8, ["disabled", "value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: "邮箱",
                        name: "email"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.person.email,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.person.email) = $event)),
                            autocomplete: "off"
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: "职称",
                        name: "level"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.data.formState.level,
                            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.data.formState.level) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select_option, { value: "Junior" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.STATUS("levelType").getDesc("Junior")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "Intermediate" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.STATUS("levelType").getDesc("Intermediate")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "Senior" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.STATUS("levelType").getDesc("Senior")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "Expertise" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.STATUS("levelType").getDesc("Expertise")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "执业证书",
                name: "doctorImage"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.imageList, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                        _createVNode(_component_UploadImagePreview, {
                          path: item.path,
                          fileUrl: _ctx.data.imageList[index].path + '/avatar',
                          onOnSuccess: (url) => _ctx.onSuccess(url, index),
                          onOnRemove: ($event: any) => (_ctx.onRemove(index))
                        }, null, 8, ["path", "fileUrl", "onOnSuccess", "onOnRemove"]),
                        _createElementVNode("span", null, _toDisplayString(item.title), 1)
                      ]))
                    }), 128))
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "所属医院",
                name: "clinicIds"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.hospitalSelectOption, (item) => {
                    return (_openBlock(), _createBlock(_component_a_tag, {
                      color: "green",
                      closable: "",
                      key: item.value,
                      onClose: ($event: any) => (_ctx.handleClose(item.value)),
                      onClick: ($event: any) => (_ctx.toClinic(item.value)),
                      style: {"cursor":"pointer"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClose", "onClick"]))
                  }), 128)),
                  _createVNode(_component_a_select, {
                    getPopupContainer: (node) => node.parentNode || _ctx.document.body,
                    value: _ctx.data.formState.clinicIds,
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.data.formState.clinicIds) = $event)),
                    bordered: false,
                    "max-tag-count": 0,
                    "max-tag-placeholder": '点击选择',
                    "show-search": "",
                    placeholder: "点击选择",
                    style: {"width":"200px"},
                    "default-active-first-option": false,
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": null,
                    mode: "multiple",
                    options: _ctx.data.hospitalOption,
                    onSearch: _ctx.handleSearch,
                    onChange: _ctx.handleChange
                  }, null, 8, ["getPopupContainer", "value", "options", "onSearch", "onChange"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "擅长领域",
                name: "areasOfExpertise"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_textarea, {
                    value: _ctx.data.formState.areasOfExpertise,
                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.data.formState.areasOfExpertise) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "执业特点",
                name: "practiceCharacteristics"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_textarea, {
                    value: _ctx.data.formState.practiceCharacteristics,
                    "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.data.formState.practiceCharacteristics) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "关联治疗项",
                name: "treatIds"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.data.formState.treatIds,
                    "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.data.formState.treatIds) = $event)),
                    options: _ctx.data.options,
                    mode: "multiple"
                  }, null, 8, ["value", "options"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "展示病例",
                name: "exhibitCaseIds"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.data.formState.exhibitCaseIds,
                    "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.data.formState.exhibitCaseIds) = $event)),
                    options: _ctx.data.exhibitCaseOptions,
                    mode: "multiple"
                  }, null, 8, ["value", "options"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: "职位",
                        name: "treeValue"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_tree_select, {
                            value: _ctx.data.formState.positionId,
                            "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.data.formState.positionId) = $event)),
                            "show-search": "",
                            style: {"width":"100%"},
                            onFocus: _ctx.selectPositions,
                            "dropdown-style": { maxHeight: '400px', overflow: 'auto' },
                            placeholder: "Please select",
                            "allow-clear": "",
                            "tree-default-expand-all": "",
                            "tree-data": _ctx.data.treeData,
                            "field-names": {
                    children: 'children',
                    title: 'name',
                    value: 'id',
                  }
                          }, {
                            title: _withCtx(({ name }) => [
                              _createElementVNode("span", null, _toDisplayString(name), 1)
                            ]),
                            _: 1
                          }, 8, ["value", "onFocus", "tree-data"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _withDirectives(_createVNode(_component_a_form_item, { label: "登录密码" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input_password, {
                            value: _ctx.data.formState.password,
                            "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.data.formState.password) = $event)),
                            placeholder: "密码默认为手机号后六位",
                            autocomplete: "new-password"
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 512), [
                        [_vShow, !_ctx.data.doctorId]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["model", "label-col", "wrapper-col", "rules"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_modal, {
      visible: _ctx.data.addHospitalvisible,
      "onUpdate:visible": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.data.addHospitalvisible) = $event)),
      title: "新建医院",
      confirmLoading: _ctx.data.addHospitalloading,
      onOk: _ctx.onOk
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AddHospitalVue, {
          ref: "addHospitalRef",
          onOnCancel: _ctx.onCancel,
          id: _ctx.data.hospitalId,
          type: 'update'
        }, null, 8, ["onOnCancel", "id"])
      ]),
      _: 1
    }, 8, ["visible", "confirmLoading", "onOk"])
  ]))
}