import request from '@/utils/request'


export const getSaleOrderQrCode: any = (saleOrderId: any) => {
    return request({
        url: `/saleOrder/wxpay/${saleOrderId}`,
        method: 'get',
    })
}

export const getSaleOrderWxPayStatus: any = (saleOrderId: any) => {
    return request({
        url: `/saleOrder/wxpay/status/${saleOrderId}`,
        method: 'get',
    })
}

export const getSaleOrder: any = (saleOrderId: any) => {
    return request({
        url: `/saleOrder/${saleOrderId}`,
        method: 'get',
    })
}

export const saleOrderRefund: any = (saleOrderId: any) => {
    return request({
        url: `/saleOrder/saleOrderRefund/${saleOrderId}`,
        method: 'put',
    })
}

export const saleOrderUnrelated: any = (saleOrderId: any) => {
    return request({
        url: `/saleOrder/saleOrderUnrelated/${saleOrderId}`,
        method: 'put',
    })
}

export const getSaleOrderByPatientIdAndTreatId: any = (data: any) => {
    return request({
        url: `/saleOrder/getSaleOrderByPatientIdAndTreatId`,
        method: 'post',
        data: data
    })
}

export const getTreatTypes: any = (patientId: string) => {
    return request({
        url: `/system/treat/getTreatTypes/${patientId}`,
        method: 'get',
    })
}

export const getTreatDetailById: any = (id: string) => {
    return request({
        url: `/system/treat/detail/${id}`,
        method: 'get',
    })
}


